<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="8" lg="8" cols="12">
          <b-card-actions
              class="list-card"
              ref="stok-listesi"
              @refresh="refreshStop('list')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Firma Listesi</h4>
                </b-col>
              </div>
            </b-row>
            <table class="table table-hover table-sm mt-5" :class="width < 1200 ? ['table-responsive-md']:''">
              <thead>
              <tr>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Firma Ünvan
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >
                  Firma Adı
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >
                  Telefon
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >
                  Adres
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >
                  Vergi No
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >
                  Vergi Dairesi
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                ></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item ,i) in firmaListesi" :key="i">
                <td style="width: 20%" @click="firmaDetay = item" v-b-modal.firma-detay>
                  {{ item.firmaUnvan || item.firmaAdi }}
                </td>
                <td style="width: 15%" @click="firmaDetay = item" v-b-modal.firma-detay>
                  {{ item.firmaAdi }}
                </td>
                <td style="width: 10%" @click="firmaDetay = item" v-b-modal.firma-detay>
                  {{ item.firmaTelefon }}
                </td>
                <td style="width: 24%" @click="firmaDetay = item" v-b-modal.firma-detay>
                  {{ item.firmaAdres }}
                </td>
                <td style="width: 10%" @click="firmaDetay = item" v-b-modal.firma-detay>
                  {{ item.firmaVergiNo }}
                </td>
                <td style="width: 10%" @click="firmaDetay = item" v-b-modal.firma-detay>
                  {{ item.firmaVergiDairesi }}
                </td>
                <td style="width: 10%">
                  <b-row>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        class="btn-icon rounded-circle mr-50"
                        v-b-tooltip.left="'Düzenle'"
                        size="sm"
                        @click="updateStepOne(item)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen"/>
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="btn-icon rounded-circle mr-50"
                        v-b-tooltip.right="'Sil'"
                        size="sm"
                        @click="remove(item.firmaID)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </b-row>
                </td>
              </tr>
              </tbody>
            </table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="12">
                <p class="text-muted">Toplam {{ toplamKayit }} Kayıt bulundu</p>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col xl="4" lg="4" cols="12">
          <b-card-actions
              class="list-card"
              ref="stok-listesi"
              @refresh="refreshStop('list')"
          >
            <b-row class="mt-50">
              <div class="card-header-row"
                   :style="{backgroundColor: !edit ? $store.getters.COLORS_GET.cardTitle : 'orange'}"
              >
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">{{
                      edit ? 'Firma Düzenleniyor' : 'Firma Kaydet'
                    }}</h4>
                </b-col>
              </div>
            </b-row>
            <b-row class="mt-5">
              <b-col cols="12">
                <b-form-group
                    label="Firma Adı"
                    label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="fa-solid fa-user"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="hi-first-name"
                        autocomplete="off"
                        v-model="firmaSchema.adi"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Firma Ünvan"
                    label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="fa-solid fa-building"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="hi-email"
                        autocomplete="off"
                        v-model="firmaSchema.unvan"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Telefon"
                    label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="fa-solid fa-phone"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="hi-number"
                        autocomplete="off"
                        v-model="firmaSchema.telefon"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Adres"
                    label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="fa-solid fa-location-dot"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="hi-password"
                        autocomplete="off"
                        v-model="firmaSchema.adres"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Vergi No"
                    label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="fa-solid fa-hashtag"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="hi-password"
                        autocomplete="off"
                        v-model="firmaSchema.vergiNo"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Vergi Dairesi"
                    label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="fa-solid fa-landmark"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="hi-password"
                        autocomplete="off"
                        v-model="firmaSchema.vergiDairesi"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col offset-md="4">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                    @click="!edit  ? save() : updateStepSecond()"
                    :disabled="!firmaSchema.unvan"
                >
                  {{ edit ? 'Güncelle' : 'Kaydet' }}
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="submit"
                    variant="outline-secondary"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none',color:'white',border:'none!important'}"
                    @click="reset"
                    v-if="edit"
                >
                  {{ edit ? 'Vazgeç' : 'Sıfırla' }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="firma-detay"
        ok-only
        ok-title="Kapat"
        modal-class="modal-primary"
        centered
        :title="firmaDetay.firmaUnvan + ' Firmasının Bilgileri' || firmaDetay.firmaAdi + ' Firmasının Bilgileri'"
        size="lg"
    >
      <p class="d-inline-block">Firma Adı : </p>
      <p class="d-inline-block pl-2 font-weight-bolder pt-50 cursor-pointer"
         @click="copy(firmaDetay.firmaAdi)"
      >{{
          firmaDetay.firmaAdi
        }}</p>
      <hr/>
      <p class="d-inline-block">Firma Unvan : </p>
      <p class="d-inline-block pl-2 font-weight-bolder cursor-pointer"
         @click="copy(firmaDetay.firmaUnvan)"
      >
        {{ firmaDetay.firmaUnvan }}</p>
      <hr/>
      <p class="d-inline-block">Firma Telefonu : </p>
      <p class="d-inline-block pl-2 font-weight-bolder cursor-pointer"
         @click="copy(firmaDetay.firmaTelefon)"
      >
        {{ firmaDetay.firmaTelefon }}</p>
      <hr/>
      <p class="d-inline-block">Firma Adresi : </p>
      <p class="d-inline-block pl-2 font-weight-bolder cursor-pointer"
         @click="copy(firmaDetay.firmaAdres)"
      >
        {{ firmaDetay.firmaAdres }}</p>
      <hr/>
      <p class="d-inline-block">Firma Vergi No : </p>
      <p class="d-inline-block pl-2 font-weight-bolder cursor-pointer"
         @click="copy(firmaDetay.firmaVergiNo)"
      >
        {{ firmaDetay.firmaVergiNo }}</p>
      <hr/>
      <p class="d-inline-block">Firma Vergi Dairesi : </p>
      <p class="d-inline-block pl-2 font-weight-bolder cursor-pointer"
         @click="copy(firmaDetay.firmaVergiDairesi)"
      >
        {{ firmaDetay.firmaVergiDairesi }}</p>
    </b-modal>
  </section>
</template>
<script>
import {
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vMultiselectListbox from 'vue-multiselect-listbox-v2'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'
import axios from 'axios'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BContainer,
    vMultiselectListbox,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      firmaDetay: {
        adi: '',
        unvan: '',
        adres: '',
        telefon: '',
        vergiNo: '',
        vergiDairesi: '',
      },
      firmaSchema: {
        adi: '',
        unvan: '',
        adres: '',
        telefon: '',
        vergiNo: '',
        vergiDairesi: '',
      },
      edit: false,
      updateID: '',
      name: '',
      oldName: '',
      toplamKayit: '',
    }
  },
  computed: {
    firmaListesi() {
      return store.getters.FIRMA_LISTESI_GET
    },
  },
  watch: {
    edit(val) {
      if (val == false) {
        this.name = ''
      }
    },
  },
  methods: {
    reset() {
      this.edit = false
      this.firmaSchema = {
        adi: '',
        unvan: '',
        adres: '',
        telefon: '',
        vergiNo: '',
        vergiDairesi: '',
      }
    },
    copy(context) {
      navigator.clipboard.writeText(context)
          .then((position = 'bottom-right') => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: 'Kopyalandı',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }, () => {
          })
    },
    updateStepOne(item) {
      this.edit = true
      this.firmaSchema.adi = item.firmaAdi
      this.firmaSchema.unvan = item.firmaUnvan
      this.firmaSchema.telefon = item.firmaTelefon
      this.firmaSchema.adres = item.firmaAdres
      this.firmaSchema.vergiNo = item.firmaVergiNo
      this.firmaSchema.vergiDairesi = item.firmaVergiDairesi
      this.updateID = item.firmaID
    },
    updateStepSecond() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'ihale')
      fd.append('methodName', 'ihaleFirmaDuzenle')
      fd.append('firmaID', this.updateID)
      fd.append('updateList[firmaAdi]', this.firmaSchema.adi)
      fd.append('updateList[firmaUnvan]', this.firmaSchema.unvan)
      fd.append('updateList[firmaAdres]', this.firmaSchema.adres)
      fd.append('updateList[firmaTelefon]', this.firmaSchema.telefon)
      fd.append('updateList[firmaVergiNo]', this.firmaSchema.vergiNo)
      fd.append('updateList[firmaVergiDairesi]', this.firmaSchema.vergiDairesi)
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              this.edit = false
              store.dispatch('firmaListesi')
              this.updateID = ''
              this.firmaSchema.adi = ''
              this.firmaSchema.unvan = ''
              this.firmaSchema.adres = ''
              this.firmaSchema.telefon = ''
              this.firmaSchema.vergiNo = ''
              this.firmaSchema.vergiDairesi = ''
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
              store.commit('setToken', res.data.userToken)
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'warning',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    remove(firmaID) {
      store.dispatch('firmaSil', firmaID)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              if (firmaID == this.updateID) {
                this.edit = false
                this.updateID = ''
                this.firmaSchema.adi = ''
                this.firmaSchema.unvan = ''
                this.firmaSchema.adres = ''
                this.firmaSchema.telefon = ''
                this.firmaSchema.vergiNo = ''
                this.firmaSchema.vergiDairesi = ''
              }
              store.dispatch('firmaListesi')
                  .then(res => {
                    const liste = { ...res }
                    delete liste.userToken
                    this.toplamKayit = Object.keys(liste).length
                  })
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    save() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'ihale')
      fd.append('methodName', 'ihaleFirmaKaydet')
      fd.append('firmaBilgileri[firmaAdi]', this.firmaSchema.adi)
      fd.append('firmaBilgileri[firmaUnvan]', this.firmaSchema.unvan)
      fd.append('firmaBilgileri[firmaAdres]', this.firmaSchema.adres)
      fd.append('firmaBilgileri[firmaTelefon]', this.firmaSchema.telefon)
      fd.append('firmaBilgileri[firmaVergiNo]', this.firmaSchema.vergiNo)
      fd.append('firmaBilgileri[firmaVergiDairesi]', this.firmaSchema.vergiDairesi)
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              store.dispatch('firmaListesi')
                  .then(res => {
                    const liste = { ...res }
                    delete liste.userToken
                    this.toplamKayit = Object.keys(liste).length
                  })
              this.firmaSchema.adi = ''
              this.firmaSchema.unvan = ''
              this.firmaSchema.adres = ''
              this.firmaSchema.telefon = ''
              this.firmaSchema.vergiNo = ''
              this.firmaSchema.vergiDairesi = ''
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: JSON.stringify(res.data.result.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
              store.commit('setToken', res.data.userToken)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata',
                  text: JSON.stringify(res.data.result.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsDownIcon',
                  variant: 'warning',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'list'].showLoading = false
      }, 1000)
    },
  },
  created() {
    store.dispatch('firmaListesi')
        .then(res => {
          const liste = { ...res }
          delete liste.userToken
          this.toplamKayit = Object.keys(liste).length
        })
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  beforeDestroy() {
    store.commit('FIRMA_LISTESI_ACTION', 'remove')
  },
}
</script>
<style scoped>
hr {
  margin: 0.6rem;
}
</style>
